/* eslint-disable multiline-ternary */
import React, { ReactElement, useEffect, useState } from 'react';
import { List, ListUnits } from '../../ui/ModalPredicta/styles';
import ModalPredicta from '../../ui/ModalPredicta';
import {
  Container,
  BackSection,
  ProductDetails,
  TypesFaq,
  Steps,
  PlansSection,
  SliderContainer,
  MedicCard,
  ModalMedic,
  Analisys,
  AnalisysImg,
  AnalisysMobile,
  AnalisysOne,
  AnalisysFive,
  AnalisysFour,
  AnalisysThree,
  AnalisysTwo
} from './styles';

import {
  AccordionWrapper,
  CompromiseSection,
  FaqSection,
  PlansWrapper
} from '../Home/styles';

import ButtonWhatsapp from '../../ui/ButtonWhatsapp';
import Footer from '../../ui/Footer';
import { IconCheck, LogoEinstein } from '../../assets/icons';

import FactImg from '../../assets/Site/FACT.png';

import unitsPredictaByUF from '../../utils/unitsByUF';
import JciImg from '../../assets/Site/jci.png';

import WestchesterImg from '../../assets/Site/Westchester.png';
import Header from '../../ui/Header';
import HeaderMobile from '../../ui/HeaderMobile';

import Accordion from '../../ui/Accordion';

import { useDispatch, useSelector } from 'react-redux';
import { ScheduleRootState, Item, FIRST_ITEMS } from '../../store/redux';
import Plan from '../../components/Plan';

import Slider from 'react-slick';
import CardsModal from '../../components/CardsModal';

import BannerImg from '../../assets/Site/banner-predicta-infinity.jpg';
import BannerPlans from '../../ui/BannerPlans';
import PredictaPlanImg from '../../assets/Site/predicta-infinity.jpg';
import AnalisysSectionImg from '../../assets/Site/analisys-section-bg.svg';
import background_dna from '../../assets/Site/analisys-section-bg.jpg';

export default function LandingInfinity(): ReactElement {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [allFaq, setAllFaq] = useState(false);

  const [width, setWindowWidth] = useState(0);
  const showDesktopComponent: boolean = width > 768;

  // Responsive
  const updateDimensions = () => {
    const width = window.outerWidth;
    setWindowWidth(width);
  };

  useEffect(() => {
    updateDimensions();

    window.addEventListener('resize', updateDimensions);
    return () => window.removeEventListener('resize', updateDimensions);
  }, []);

  const accordionItems = useSelector<ScheduleRootState, Item[] | undefined>(
    (scheduleReducer) => scheduleReducer.filtered
  );

  function allItems(): void {
    setAllFaq(!allFaq);
    dispatch({ type: FIRST_ITEMS, number: allFaq ? 5 : 0 });
  }

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    centerMode: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          infinite: true,
          autoplay: true,
          autoplaySpeed: 3000,
          centerMode: false,
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <>
      {showDesktopComponent ? <Header /> : <HeaderMobile />}

      <ModalPredicta
        open={open}
        setOpen={setOpen}
        title="NOSSOS LABORATÓRIOS"
        children={
          <>
            <List>
              <ul>
                <li>
                  <p>
                    Utilizamos tecnologia de sequenciamento de segunda
                    geração para realizar a análise do DNA. O processo é em
                    grande parte automatizado, o que minimiza o risco de
                    falhas humanas, aumentando a assertividade.
                  </p>
                </li>
                <li>
                  <p>
                    A sua amostra de sangue deverá ser coletada em nossos
                    laboratórios do Hospital Israelita Albert Einstein.
                    Veja as unidades:
                  </p>
                </li>
              </ul>
              <ListUnits>
                {
                  Object.keys(unitsPredictaByUF).map(key => (
                    <div>
                      <h3>{unitsPredictaByUF[key].uf}</h3>
                      <ul>
                        {unitsPredictaByUF[key].units.map((unity: any) => (
                          <li>
                            <p>{unity}</p>
                          </li>
                        ))}
                      </ul>
                    </div>
                  ))
                }
              </ListUnits>
            </List>
          </>
        }
      />

      <Container>
        <BannerPlans
          image={BannerImg}
          productName="PREDICTA"
          productSecondName="INFINITY"
          title="Mapeamento completo para viver mais e melhor."
          textButton="Saiba mais"
          linkButton="#contratar"
        />

        <ProductDetails id="productdetails">
          <div>
            <h2>Predicta Infinity</h2>
            <ul>
              <li>
                Uma solução completa em genética. Assim pode ser definido o
                Predicta Infinity. Muito mais que um exame, é oferecido também um
                acompanhamento com especialistas, além de uma reanálise do
                exame.
              </li>
              <li>
                Por ser uma avaliação mais completa, o Predicta Infinity
                é feito a partir de uma amostra de sangue colhida do paciente
                em nossos laboratórios do Hospital Israelita Albert Einstein&nbsp;
                <button onClick={() => setOpen(true)} className="text-blue">(veja aqui as unidades)</button>.
                Todo o genoma será avaliado para detecção de alterações capazes
                de causar doenças.
              </li>
              <li>
                Tudo começa com uma consulta pré-teste com médico geneticista e,
                após os resultados, uma nova avaliação profissional é feita com
                discussão dos resultados e suas implicações. Como novas descobertas
                acontecem na área da genética periodicamente, os laudos com alterações
                serão revisados e tais resultados serão atualizados no segundo, terceiro,
                quarto e quinto anos do acompanhamento com um médico geneticista.<br></br><br></br>

                <strong>Importante:</strong> O teste não exige solicitação médica.<br></br><br></br>

                Temos uma equipe especializada para tirar suas dúvidas, clique no ícone
                do Whatsapp e escolha a opção Predicta.
              </li>
            </ul>
          </div>
          <div>
            <img src={PredictaPlanImg} />
          </div>
          {/* <a href="/contratar">
            Valor <b>R$15.500,00</b>
          </a> */}
        </ProductDetails>

        <Analisys>
          <h2>O que é analisado no Predicta Infinity</h2>
          <AnalisysImg>
            <img src={AnalisysSectionImg}></img>
          </AnalisysImg>
          <AnalisysMobile>
            <AnalisysOne>
              Avaliação de risco para o desenvolvimento de condições genéticas em
              563 genes.
            </AnalisysOne>
            <AnalisysTwo>
              Análise de alterações genéticas para verificar quais os medicamentos  são mais efetivos, ou contra-indicados, para o seu organismo. São analisadas substâncias voltadas para doenças cardiovasculares, hematológicas, gástricas, infecciosas, neurológicas, oncológicas e psiquiátricas.
            </AnalisysTwo>
            <AnalisysThree>
              Informações sobre ancestralidade,revelando sua origem genética.
            </AnalisysThree>
            <AnalisysFour>
              Algumas pessoas possuem alterações em genes recessivos associados a doenças, que não se desenvolvem por possuírem uma outra cópia do gene que não está alterada. A transmissão dessas cópias alteradas podem trazer riscos reprodutivos,  caso o cônjuge também as transmita.
            </AnalisysFour>
            <img className='background_dna' src={background_dna}></img>
          </AnalisysMobile>
        </Analisys>

        <TypesFaq>
          <FaqSection id="types">
            <h3>
              Confira abaixo a lista de doenças, de origem genética, que podem
              ter seus riscos aumentados detectados pelo Predicta Infinity:{' '}
            </h3>
            <AccordionWrapper className="holder">
              <Accordion btnColor="#00B193" title={'Doenças Clinicamente Acionáveis'} key={1}>
                <div className="main">
                  <div className="title">
                    <div>GENES</div>
                    <div>SUSCETIBILIDADE AO CÂNCER</div>
                  </div>
                  <div className="row">
                    <div className="row-section">
                      ABRAXAS1, AKT1, AR, ATM, BARD1, BRCA1, BRCA2, BRIP1, CD82, CHEK2, CYP19A1, DICER1, ESR1, GEN1, HIP1, HMMR, HOXB13, KLLN, LSP1, MAP3K1, MRE11, MSR1, MXI1, NBN, NQO2, OPCML, PALB2, PHB, PIKC3A, PPM1D, PTEN, RAD50, RAD51, RAD51C, RAD51D, RAD54L, RBBP8, RECQL, RECQL4, RINT1, SEC23B, SMARCA4, TOX3, TP53, XRCC2.
                    </div>
                    <div className="row-section">
                      <b>Câncer de mama hereditário (45 genes): </b><br />
                      • Síndrome de câncer de mama e ovário hereditário <br />
                      • Síndrome do tumor PTEN-hamartoma <br />
                      • Síndrome Li-Fraumeni <br />
                      • Câncer reprodutivo (ginecológico, próstata) <br />
                    </div>
                  </div>
                  <div className="row">
                    <div className="row-section">
                      APC, BMPR1A, MLH1, MSH2, MSH6, MUTYH, PMS2, SMAD4, STK11, AURKA, AXIN2, BUB1B, CCND1, CDH1, CTNNA1, CTNNB1, CTRC, EPCAM, FAN1, GALNT12, GREM1, KIT, MLH3, MSH3, NTHL1, ODC1, PALLD, PDGFRA, PLA2G2A, PMS1, POLD1, POLE, PRSS1, RPS20, SPINK1, TLR2.
                    </div>
                    <div className="row-section">
                      <b>Polipose e câncer gastrointestinal hereditário (36 genes):</b><br />
                      • Síndrome de Lynch <br />
                      • Polipose adenomatosa familiar <br />
                      • Polipose associada ao MUTYH <br />
                      • Polipose juvenil <br />
                      • Síndrome de Peutz-Jeghers <br />
                      • Pancreatite hereditária <br />
                    </div>
                  </div>
                  <div className="row">
                    <div className="row-section">
                      NF1, NF2, RB1, SMARCB1, TSC1, TSC2.
                    </div>
                    <div className="row-section">
                      <b>Neurocutâneos e oculares (6 genes): </b><br />
                      • Neurofibromatose tipo 1 e 2 <br />
                      • Complexo de esclerose tuberosa tipo 1 e 2 <br />
                      • Retinoblastoma <br />
                    </div>
                  </div>
                  <div className="row">
                    <div className="row-section">
                      AIP, BAP1, CDC73, CDKN1B, CDKN1C, DIS3L2, EGLN1, EGLN2, EPAS1, FH, FLCN, H19, HABP2, KIF1B, MAX, MAX, MEN1, MET, MITF, PRKAR1A, RET, SDHA, SDHAF2, SDHB, SDHC, SDHD, SRGAP1, TMEM127, TRIM37, TSHR, VHL, WT1.
                    </div>
                    <div className="row-section">
                      <b>Neuroendócrino e renal (31 genes): </b><br />
                      • Câncer medular familiar da tireóide <br />
                      • Síndrome hereditária de paraganglioma-feocromocitoma <br />
                      • Múltiplas neoplasias endócrinas tipos 1 e 2 <br />
                      • Síndrome de von Hippel-Lindau <br />
                      • Tumor de Wilms relacionado ao WT1 <br />
                    </div>
                  </div>
                  <div className="row">
                    <div className="row-section">
                      ACD, ALK, ARID5B, ATR, CASP8, CBX8, CDK4, CDKN2A, CEBPA, CEBPE, CEP57, COL7A1, CREBBP, CTC1, CTLA4, CYLD, DDB2, DDR2, DDX41, DKC1, DOCK8, EGFR, ELANE, EP300, ERBB2, ERCC1, ERCC2, ERCC3, ERCC4, ERCC5, ETV6, EXO1, EXT1, EXT2, EZH2, FANCA, FANCB, FANCC, FANCD2, FANCE, FANCF, FANCG, FANCI, FANCL, FANCM, FAS, FAT1, FGFR2, G6PC3, GATA1, GATA2, GPC3, HAX1, HMBS, IKZF1, ITK, JAK2, MC1R, MGMT, MPL, MTAP, NHP2, NOP10, NSD1, PARN, PAX5, PHOX2B, PICALM, POLH, POT1, PRF1, PRKDC, PTCH1, PTCH2, RAD51B, RBM15, RHBDF2, ROBO2, ROS1, RPL11, RPL15, RPL26, RPL27, RPL31, RPL35A, RPL5, RPS10, RPS19, RPS24, RPS26, RPS27, RPS28, RPS29, RPS7, RTEL1, RUNX1, SBDS, SH2D1A, SLX4, SMARCE1, STAT3, SUFU, TERC, TERT, TINF2, TYR, UROD, USB1, WAS, WRAP53, WRN, XPA, XPC, XRCC3.
                    </div>
                    <div className="row-section">
                      <b>Outros cânceres (114 gens): </b><br />
                      • Pele <br />
                      • Hematológicos <br />
                      • Tumores sólidos <br />
                    </div>
                  </div>
                </div>
              </Accordion>
              <Accordion btnColor="#00B193" title={'Doenças Cardiovasculares'} key={2}>
                <div className="main">
                  <div className="title">
                    <div>GENES</div>
                    <div>CARDIOVASCULAR</div>
                  </div>
                  <div className="row">
                    <div className="row-section">
                      ACTA1, ACTC1, ACTN2, ALMS1, ANKRD1, BAG3, CALR3, CAV3, CAVIN4, CHRM2, COX15, CRYAB, CSRP3, CTF1, DES, DMD, DOLK, DPP6, DSC2, DSG2, DSP, DTNA, EMD, EYA4, FHL1, FHL2, FLNC, GATAD1, GLA, HADHA, ILK, JPH2, JUP, KLF10, LAMA4, LAMP2, LDB3, LMNA, MIB1, MYBPC3, MYH6, MYH7, MYL2, MYL3, MYLK2, MYO6, MYOM1, MYOZ2, MYPN, NEBL, NEXN, PDLIM3, PKP2, PLN, PRDM16, PRKAG2, PSEN1, PSEN2, RBM20, SCO2, SELENON, SGCB, SGCD, SLC25A13, SLC25A4, TAZ, TCAP, TMEM43, TMPO, TNNC1, TNNI3, TNNT2, TPM1, TRIM63, TTN, TTN, TTR, TXNRD2, VCL, ZBTB17.
                    </div>
                    <div className="row-section">
                      <b>Cardiomiopatia (79 genes):</b><br />
                      • Cardiomiopatia hipertrófica <br />
                      • Cardiomiopatia dilatada <br />
                      • Displasia arritmogênica do ventrículo direito <br />
                      • Doença de Fabry <br />
                    </div>
                  </div>
                  <div className="row">
                    <div className="row-section">
                      BRAF, CBL, HRAS, KRAS, LZTR1, MAP2K1, MAP2K2, NRAS, PTPN11, RAF1, RIT1, SHOC2, SOS1.
                    </div>
                    <div className="row-section">
                      <b>RASopatías (13 genes):</b><br />
                      • Síndrome de Noonan <br />
                      • Síndrome de Costello <br />
                      • Síndrome cardio-facio-cutâneo <br />
                    </div>
                  </div>
                  <div className="row">
                    <div className="row-section">
                      ABCC9, AKAP9, ANK2, CACNA1C, CACNB2, CALM1, CALM2, CALM2, CASQ2, GJA5, GPD1L, HCN4, KCNA5, KCND3, KCNE1, KCNE2, KCNE3, KCNE5, KCNH2, KCNJ2, KCNJ5, KCNJ8, KCNQ1, NPPA, RANGRF, RYR2, SCN1B, SCN2B, SCN3B, SCN4B, SCN5A, SLMAP, SNTA1, TRDN, TRPM4.
                    </div>
                    <div className="row-section">
                      <b>Canalopatias e arritimias (35 genes): </b><br />
                      • Síndrome QT-longo Romano-Ward tipos 1, 2 e 3 <br />
                      • Síndrome de Brugada <br />
                      • Taquicardia ventricular polimórfica catecolaminérgica <br />
                    </div>
                  </div>
                  <div className="row">
                    <div className="row-section">
                      APOB, LDLR, PCSK9, ABCG5, ABCG8, APOA5, APOC2, APOE, CETP, CREB3L3, GPIHBP1, LDLRAP1, LMF1, SREBF2.
                    </div>
                    <div className="row-section">
                      • Hipercolesterolemia familiar (14 genes)
                    </div>
                  </div>
                </div>
              </Accordion>
              <Accordion
                btnColor="#00B193"
                title={'Doenças do Tecido Conjuntivo'}
                key={2}
              >
                <div className="main">
                  <div className="title">
                    <div>GENES</div>
                    <div>TECIDO CONJUNTIVO</div>
                  </div>
                  <div className="row long">
                    <div className="row-section">
                      ACTA2, FBN1, MYH11, SMAD3, TGFBR1, TGFBR2, CCM2, ELN, FBN2, FLNA, LTBP2, LOX, MYLK, NKX2-5, NODAL, NOTCH1, NOTCH3, PDCD10, PRKG1, PRNP, SKI, SLC2A10, TGFB2, TGFB3.
                    </div>
                    <div className="row-section">
                      <b>Aortopatias e doenças relacionadas (24 genes):</b><br />
                      • Síndrome de Marfan <br />
                      • Síndrome de Loeys-Dietz <br />
                      • Aneurisma e dissecção da aorta familiar <br />
                    </div>
                  </div>
                  <div className="row long">
                    <div className="row-section">
                      COL3A1, ADAMTS2, B3GALT6, B4GALT7, CHST14, COL1A1, COL1A2, COL5A1, COL5A2, DSE, EFEMP2, FKBP14, PLOD1, PRDM5, SLC39A13, TNXB, ZNF469.
                    </div>
                    <div className="row-section">
                      • Síndrome de Ehlers-Danlos, tipos vascular e não-vascular (17 genes)
                    </div>
                  </div>
                </div>
              </Accordion>
              <Accordion
                btnColor="#00B193"
                title={'Doenças Autossômicas Recessivas'}
                key={2}
              >
                <div className="main">
                  <div className="title">
                    <div>GENES</div>
                    <div>CONDIÇÃO DE PORTADOR</div>
                  </div>
                  <div className="row long">
                    <div className="row-section">
                      ACADM, ACADSB, ACADVL, ACSF3, AGA, AGL, AIRE, ALDOB, ALPL, ARSA, ASNS, ASPA, BBS1, BBS2, BCHE, BCKDHA, BCKDHB, BLM, CBS, CERKL, CFTR, CHRNE, CLN5, CLRN1, CNGB3, CPT1A, CPT2, CTNS, CYBA, CYP11B1, CYP11B2, CYP1B1, CYP21A2, CYP27A1, DCLRE1C, DHCR7, DNAJC19, DPYD, DYSF, ELP1, ERCC6, ETFDH, EVC, EYS, F11, FAH, FAM161A, FKTN, G6PC, G6PD, GALC, GALK1, GALT, GBA, GBE1, GCDH, GJB2, GJB6, GLB1, GLE1, GNE, HBB, HEXA, HEXB, HLCS, HPS1, HYLS1, IVD, LAMA2, LIPA, LPL, LRPPRC, MCOLN1, MED17, MEFV, MESP2, MKS1, MLC1, MMUT, MPV17, MTHFR, MYO7A, NDRG1, NDUFS6, NPHS1, OPA3, PAH, PC, PCDH15, PEX12, PEX6, PKHD1, PMM2, POLG, PPT1, PYGM, RMRP, SACS, SEPSECS, SGCG, SLC12A3, SLC12A6, SLC17A5, SLC22A5, SLC25A15, SLC26A2, SLC26A4, SMPD1, SPG11, TCIRG1, TECPR2, TOR1A, TPP1, TRMU, TSFM, USH2A.
                    </div>
                    <div className="row-section">
                      <b>Doenças autossômicas recessivas (116 gens):</b><br />
                      • Anemia falciforme (célula falciforme)<br />
                      • Ausência congênita bilateral de ducto deferente<br />
                      • Talassemia beta<br />
                      • Deficiência de acil-CoA desidrogenase de cadeia média (MCAD)<br />
                      • Deficiência de galactoquinase com cataratas<br />
                      • Diabetes mellitus (neonatal)<br />
                      • Disautonomia familiar<br />
                      • Doença de armazenamento de glicogênio <br />
                      • Doença de Canavan<br />
                      • Doença de urina semelhante ao xarope de bordo<br />
                      • Doença de Niemann-Pick<br />
                      • Doença de Tay-Sachs<br />
                      • Fenilcetonúria<br />
                      • Fibrose cística<br />
                      • Galactosemia<br />
                      • Gangliosidose GM1<br />
                      • Hiperfenilalaninemia<br />
                      • Hiperinsulinismo familiar<br />
                      • Mucolipidose tipo IV<br />
                      • Mucopolissacaridose tipo IVB (Morquio)<br />
                      • Retinose pigmentar<br />
                      • Síndrome de Bloom<br />
                      • Síndrome de Smith-Lemli-Opitz<br />
                      • Síndrome de Usher<br />
                      • Surdez<br />
                      • Acidemia glutárica<br />
                      • Acidemia isovalérica<br />
                      • Acidúria 3-metilglutacônica<br />
                      • Acidúria malônica e metilmalônica (combinada)<br />
                      • Acidúria metilmalônica<br />
                      • Acondrogênese<br />
                      • Acromatopsia<br />
                      • Aspartilglucosaminúria<br />
                      • Ataxia espástica<br />
                      • Ataxia espinocerebelar<br />
                      • Cistinose<br />
                      • Defeitos de glicosilação congênita<br />
                      • Deficiência de asparagina sintetase<br />
                      • Deficiência de carnitina palmitoiltransferase<br />
                      • Deficiência de dihidropirimidina desidrogenase<br />
                      • Deficiência oxidativa combinada de fosforilação<br />
                      • Deficiência de glicose-6-fosfato desidrogenase<br />
                      • Deficiência de holocarboxilase sintetase<br />
                      • Deficiência do fator XI<br />
                      • Deficiência de lipoproteína lipase<br />
                      • Deficiência de translocase ornitina (síndrome de HHH)<br />
                      • Deficiência de piruvato carboxilase<br />
                      • Deficiência de pseudocolinesterase<br />
                      • Deficiência de complexo mitocondrial I<br />
                      • Deficiência primária de carnitina<br />
                      • Deficiência de acil-CoA desidrogenase de cadeia curta (SCAD)<br />
                      • Deficiência de Acil-CoA Desidrogenase de Cadeia Muito Longa (VLCAD)<br />
                      • Degeneração macular juvenil<br />
                      • Disgenesia do segmento anterior: Disostose espondilocostal<br />
                      • Displasia anauxética<br />
                      • Displasia metafisária<br />
                      • Distonia-1 (torção)<br />
                      • Distrofia de cones e batonetes<br />
                      • Distrofia muscular<br />
                      • (Miyoshi, distroglicanopatia, deficiente em merosina)<br />
                      • Distrofia muscular do tipo cinturas<br />
                      • Doença de armazenamento de ácido siálico<br />
                      • Doença de Charcot-Marie-Tooth<br />
                      • Doença de Gaucher<br />
                      • Doença de Krabbe<br />
                      • Doença de McArdle<br />
                      • Doença de Sandhoff<br />
                      • Doença de Wolman<br />
                      • Doença granulomatosa crônica<br />
                      • Doença de armazenamento de éster de colesterol<br />
                      • Doença renal policística<br />
                      • Esclerose lateral amiotrófica (juvenil)<br />
                      • Doença de corpos de poliglucosano: Espectro da síndrome de Zellweger<br />
                      • Febre familiar do Mediterrâneo<br />
                      • Glaucoma congênito<br />
                      • Hiperandrogenismo<br />
                      • Hiperplasia adrenal congênita (deficiência de 11-beta-hidroxilase e deficiência de 21- hidroxilase)<br />
                      • Hipoaldosteronismo (congênito)<br />
                      • Hipofosfatasia<br />
                      • Hipoplasia cartilagem-cabelo<br />
                      • Hipoplasia pontocerebelar<br />
                      • Homocistinúria<br />
                      • Imunodeficiência combinada grave<br />
                      • Insuficiência hepática transitória infantil<br />
                      • Intolerância hereditária à frutose<br />
                      • Leucodistrofia metacromática<br />
                      • Leucoencefalopatia megalencefálica<br />
                      • Lipofuscinose neuronal neuroide (CLN1, CLN2 e CLN5)<br />
                      • Microcefalia pós-natal progressiva<br />
                      • Miopatía distal de início no músculo tibial anterior<br />
                      • Miopatia de Nonaka<br />
                      • Oftalmoplegia externa progressiva<br />
                      • Osteopetrose<br />
                      • Paraplegia espástica<br />
                      • Síndrome de Andermann<br />
                      • Síndrome de Bardet-Biedl<br />
                      • Síndrome de Cockayne<br />
                      • Síndrome de ataxia mitocondrial recessiva<br />
                      • Síndrome de contratura congênita letal<br />
                      • Síndrome de depleção de DNA mitocondrial (síndrome de ataxia mitocondrial recessiva)<br />
                      • Síndrome de Ellis-van Creveld<br />
                      • Síndrome de Gitelman<br />
                      • Síndrome de Heimler<br />
                      • Síndrome de Hermansky-Pudlak<br />
                      • Síndrome de Joubert<br />
                      • Síndrome de Leigh<br />
                      • Síndrome de Meckel<br />
                      • Síndrome de Omenn<br />
                      • Síndrome de Pendred<br />
                      • Síndrome de polndocrinopatia auto-imune (ACEPED)<br />
                      • Síndrome de Hydroletalus<br />
                      • Síndrome miastênica congênita<br />
                      • Síndrome nefrótica<br />
                      • Síndrome UV sensível<br />
                      • Tirosinemia<br />
                      • Trombose, hiperhomocisteinemia<br />
                      • Xantomatose cerebrotendinosa<br />
                    </div>
                  </div>
                  <div className="row long">
                    <div className="row-section">
                      COL3A1, ADAMTS2, B3GALT6, B4GALT7, CHST14, COL1A1, COL1A2, COL5A1, COL5A2, DSE, EFEMP2, FKBP14, PLOD1, PRDM5, SLC39A13, TNXB, ZNF469.
                    </div>
                    <div className="row-section">
                      • Síndrome de Ehlers-Danlos, tipos vascular e não-vascular (17 genes)
                    </div>
                  </div>
                </div>
              </Accordion>
              <Accordion btnColor="#00B193" title={'Outras doenças'} key={1}>
                <div className="main">
                  <div className="title">
                    <div>GENES</div>
                    <div>OUTRAS DOENÇAS ACIONÁVEIS</div>
                  </div>
                  <div className="row">
                    <div className="row-section">
                      CACNA1S, CACNA2D1, RYR1.
                    </div>
                    <div className="row-section">
                      Hipertermia maligna (3 genes)<br />
                    </div>
                  </div>
                  <div className="row">
                    <div className="row-section">
                      OTC.
                    </div>
                    <div className="row-section">
                      Deficiência de ornitina transcarbamilase (1 gene)<br />
                    </div>
                  </div>
                  <div className="row">
                    <div className="row-section">
                      ATP7B.
                    </div>
                    <div className="row-section">
                      Doença de Wilson (1 gene)<br />
                    </div>
                  </div>
                  <div className="row">
                    <div className="row-section">
                      BTD.
                    </div>
                    <div className="row-section">
                      Deficiência de biotinidase (1 gene)<br />
                    </div>
                  </div>
                  <div className="row">
                    <div className="row-section">
                      ABCC8, GCK, HNF1A, HNF1B, HNF4A, INS, KCNJ11.
                    </div>
                    <div className="row-section">
                      Diabetes tipo MODY (7 genes)<br />
                    </div>
                  </div>
                  <div className="row">
                    <div className="row-section">
                      GAA.
                    </div>
                    <div className="row-section">
                      Doença de Pompe (1 gene)<br />
                    </div>
                  </div>
                  <div className="row">
                    <div className="row-section">
                      HAMP, HFE, HJV, SLC40A1, TRF2.
                    </div>
                    <div className="row-section">
                      Hemocromatose hereditária (5 genes)<br />
                    </div>
                  </div>
                  <div className="row">
                    <div className="row-section">
                      RPE65.
                    </div>
                    <div className="row-section">
                      Retinopatías relacionadas ao RPE65 (1 gene)<br />
                    </div>
                  </div>
                  <div className="row">
                    <div className="row-section">
                      ACVRL1, ENG.
                    </div>
                    <div className="row-section">
                      Telangiectasia hemorrágica hereditária (2 genes)<br />
                    </div>
                  </div>
                  <div className="row">
                    <div className="row-section">
                      F5, F9, PROC, PROS1, SERPINC1.
                    </div>
                    <div className="row-section">
                      Trombofilias (5 genes)<br />
                    </div>
                  </div>
                  <div className="row">
                    <div className="row-section">
                      ABCD1, APOA4, CASR, CRELD1, EGR2, F8, FKRP, FXN, GCKR, GDF2, HSPB8, JAG1, LITAF, MPZ, MYCN, NEFL, PKD2, PMP22, PRKN, SALL4, SERPINA1, TBX20, TBX3, TBX5, ZHX3, ZIC3.
                    </div>
                    <div className="row-section">
                      Outros (26 genes)<br />
                    </div>
                  </div>
                </div>
              </Accordion>
            </AccordionWrapper>
          </FaqSection>
        </TypesFaq>

        <Steps>
          <h3>Etapas</h3>
          <div className="titles">1º ano do acompanhamento</div>
          <div className="timeline">
            <div className="left">
              <div className="card">
                <div className="image">
                  <img
                    src={`${process.env.REACT_APP_IMAGES_S3_BUCKET}/icons/predicta/home/credit-card.png`}
                    alt="Card"
                  />
                </div>
                <h4>Pagamento</h4>
                <p>Pague em até 10 vezes sem juros.</p>
              </div>

              <div className="card">
                <div className="image">
                  <img
                    src={`${process.env.REACT_APP_IMAGES_S3_BUCKET}/icons/predicta/home/schedule.png`}
                    alt="Card"
                  />
                </div>
                <h4>
                  Agendamento On-line <br />
                  Consulta pré-exame
                </h4>
                <p>
                  Através de nossa plataforma on-line, agende sua consulta por
                  videoconferência com Médico Geneticista.
                </p>
              </div>

              <div className="card">
                <div className="image">
                  <img
                    src={`${process.env.REACT_APP_IMAGES_S3_BUCKET}/icons/predicta/home/dna.png`}
                    alt="Card"
                  />
                </div>
                <h4>
                  Agendamento On-line <br />
                  Coleta sangue
                </h4>
                <p>
                  Após a realização da consulta você poderá agendar a coleta de
                  sangue em nosso laboratório. Lembrando que não há necessidade
                  de jejum.
                </p>
              </div>

              <div className="card">
                <div className="image">
                  <img
                    src={`${process.env.REACT_APP_IMAGES_S3_BUCKET}/icons/predicta/home/reporting.png`}
                    alt="Card"
                  />
                </div>
                <h4>Resultado de exame</h4>
                <p>
                  Seu exame será analisado e você receberá um aviso quando
                  estiver pronto.
                </p>
              </div>

              <div className="card">
                <div className="image">
                  <img
                    src={`${process.env.REACT_APP_IMAGES_S3_BUCKET}/icons/predicta/home/phone.png`}
                    alt="Card"
                  />
                </div>
                <h4>
                  Agendamento On-line
                  <br />
                  Consulta pós-exame
                </h4>
                <p>
                  Após a liberação do resultado do seu exame, você poderá
                  realizar o agendamento da sua 2º consulta com o Médico
                  Geneticista, onde poderá esclarecer suas dúvidas e receber
                  orientações.
                </p>
              </div>
            </div>
          </div>
          <div className="titles second">
            2º, 3º, 4º e 5º anos de acompanhamento
          </div>
          <div className="timeline">
            <div className="right">
              <div className="card">
                <div className="image">
                  <img
                    src={`${process.env.REACT_APP_IMAGES_S3_BUCKET}/icons/predicta/home/reporting.png`}
                    alt="Card"
                  />
                </div>
                <h4>
                  Resultado <br /> (Reclassificação e Reanalise)
                </h4>
                <p>
                  Seu exame será reanalisado em nosso laboratório anualmente,
                  sem necessidade de nova coleta, e um novo relatório será
                  liberado.
                </p>
              </div>

              <div className="card">
                <div className="image">
                  <img
                    src={`${process.env.REACT_APP_IMAGES_S3_BUCKET}/icons/predicta/home/schedule.png`}
                    alt="Card"
                  />
                </div>
                <h4>
                  Agendamento On-line <br />
                  Consulta anual
                </h4>
                <p>
                  Você receberá um lembrete para realizar o agendamento de sua
                  consulta anual com o Médico Geneticista após a liberação do
                  resultado reanalisado.
                </p>
              </div>
            </div>
          </div>
        </Steps>

        <PlansSection id="contratar">
          <PlansWrapper>
            <Plan
              plan={2}
              newValue="16.640,00"
              parcel="10"
              parcelValue="1.664,00"
              modalChildren={
                <>
                  <li>
                    <p>
                      Uma solução completa em genética. Assim pode ser definido
                      o Predicta Infinity. Muito mais que um exame, é oferecido
                      um acompanhamento com especialistas, além de uma reanálise
                      do exame.
                    </p>
                  </li>
                  <li>
                    <p>
                      Por ser uma avaliação mais completa, o Predicta Infinity
                      é feito a partir de uma amostra de sangue colhida do paciente
                      em nossos laboratórios do Hospital Israelita Albert Einstein.&nbsp;
                      <button onClick={() => setOpen(true)} className="">(veja aqui as unidades)</button>.
                      Todo o genoma será avaliado para detecção de alterações capazes
                      de causar doenças.
                    </p>
                  </li>
                  <li>
                    <p>
                      Tudo começa com uma consulta pré-teste com médico geneticista e,
                      após os resultados, uma nova avaliação profissional é feita com
                      discussão dos resultados e suas implicações. Como novas descobertas
                      acontecem na área da genética periodicamente, os laudos com alterações
                      serão revisados e tais resultados serão atualizados no segundo, terceiro,
                      quarto e quinto anos do acompanhamento com um médico geneticista.<br></br><br></br>

                      <strong>Importante:</strong> O teste não exige solicitação médica.<br></br><br></br>

                      Temos uma equipe especializada para tirar suas dúvidas, clique no ícone
                      do Whatsapp e escolha a opção Predicta.
                    </p>
                  </li>
                  <li>
                    <p>
                      O Predicta Infinity identifica risco aumentado de origem
                      genética para:
                    </p>
                  </li>
                  <li>
                    <div className="blue-check">
                      <div>
                        <IconCheck />
                      </div>
                    </div>
                    <div>22 tipos de câncer</div>
                  </li>
                  <li>
                    <div className="blue-check">
                      <div>
                        <IconCheck />
                      </div>
                    </div>
                    <div>12 doenças cardiovasculares</div>
                  </li>
                  <li>
                    <div className="blue-check">
                      <div>
                        <IconCheck />
                      </div>
                    </div>
                    <div>
                      Avaliação de risco de formação de coágulos sanguíneos
                    </div>
                  </li>
                  <li>
                    <div className="blue-check">
                      <div>
                        <IconCheck />
                      </div>
                    </div>
                    <div>
                      Genes recessivos, com implicações para transmissão a cada
                      filho
                    </div>
                  </li>
                  <li>
                    <div className="blue-check">
                      <div>
                        <IconCheck />
                      </div>
                    </div>
                    <div>
                      Distúrbios metabólicos, do sistema imunológico e
                      neurológico
                    </div>
                  </li>
                </>
              }
            >
              <>
                <li>
                  <div className="blue-check">
                    <div>
                      <IconCheck />
                    </div>
                  </div>
                  <div>
                    A sua amostra de sangue deverá ser coletada em
                    nossos laboratórios do Hospital Israelita Albert Einstein&nbsp;
                    <button onClick={() => setOpen(true)} className="text-blue">(veja aqui as unidades)</button>
                  </div>
                </li>
                <li>
                  <div className="blue-check">
                    <div>
                      <IconCheck />
                    </div>
                  </div>
                  <div>
                    Sequenciamento do genoma completo com avaliação de risco
                    para o desenvolvimento de condições genéticas em 563 genes
                  </div>
                </li>
                <li>
                  <div className="blue-check">
                    <div>
                      <IconCheck />
                    </div>
                  </div>
                  <div>
                    Consulta com médico geneticista pré e pós-teste, com avaliação
                    do histórico clinico do paciente e esclarecimento quanto ao
                    resultado do teste
                  </div>
                </li>
                <li>
                  <div className="blue-check">
                    <div>
                      <IconCheck />
                    </div>
                  </div>
                  <div>
                    O resultado do teste é atualizado 1 vez ao ano durante 4 anos,
                    juntamente com a orientação de um médico geneticista em consulta
                  </div>
                </li>
                <li>
                  <div className="blue-check">
                    <div>
                      <IconCheck />
                    </div>
                  </div>
                  <div>
                    Análise de alterações genéticas para verificar quais os medicamentos  são mais efetivos, ou contra-indicados, para o seu organismo. São analisadas substâncias voltadas para doenças cardiovasculares, hematológicas, gástricas, infecciosas, neurológicas, oncológicas e psiquiátricas.
                  </div>
                </li>
              </>
            </Plan>
          </PlansWrapper>
        </PlansSection>
        <FaqSection id="ajuda">
          <h2>Ficou alguma dúvida?</h2>
          <p>Confira aqui algumas perguntas frequentes: </p>
          <AccordionWrapper>
            {accordionItems !== undefined && accordionItems?.length !== 0 ? (
              accordionItems.map((item, index) => {
                return (
                  <Accordion
                    btnColor="#00B193"
                    key={index}
                    title={String(item.title)}
                  >
                    <div dangerouslySetInnerHTML={{ __html: item.content }} />
                  </Accordion>
                );
              })
            ) : (
              <div className="span">Nenhum resultado encontrado...</div>
            )}
          </AccordionWrapper>
          <button id="btn-009" onClick={allItems} className="btn">
            {' '}
            {allFaq ? 'Ver menos' : 'Todas as dúvidas'}
          </button>
        </FaqSection>

        <CompromiseSection id="compromise">
          <LogoEinstein />
          <h4>COMPROMISSO E EXCELÊNCIA!</h4>
          {/* <p>
            Nossos processos seguem severamente a LGPD genética
            <br />
            para proteger e guardar os dados do seu DNA!
          </p> */}
          <div>
            <img src={FactImg} />
            <img src={JciImg} />
            <img src={WestchesterImg} />
          </div>
        </CompromiseSection>

        <ButtonWhatsapp phone="551136202550" />
        <Footer />
      </Container>
    </>
  );
}
